<template>
  <div v-if="selectedTypeObject" class="mainDiv">
    <form v-if="selectedTypeObject.Fields" @submit.prevent="saveIndex">
      <div class="md-layout ">
        <div class="md-layout-item">
          <md-field md-inline>
            <label>{{ $formsLabel }}</label>
            <md-input
              id="indexFormName"
              v-model="selectedTypeObject.IndexFormName"
              :disabled="true"
              name="indexFormName"
            />
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field md-inline>
            <label>Config Name</label>
            <md-input id="configName" v-model="selectedTypeObject.ConfigName" name="configName" />
          </md-field>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-checkbox v-model="barcodeAsFileName">
            Barcode Value As File Name
          </md-checkbox>
        </div>
        <div v-if="barcodeAsFileName" class="md-layout-item">
          <md-field md-inline>
            <label>Page Number</label>
            <md-input id="pageNumber" v-model="pageNumber" name="pageNumber" />
          </md-field>
        </div>
        <div v-if="barcodeAsFileName" class="md-layout-item">
          <md-field md-inline>
            <label>Barcode Number</label>
            <md-input id="barcodeNumber" v-model="barcodeNumber" name="barcodeNumber" />
          </md-field>
        </div>
      </div>
      <md-table v-model="selectedTypeObject.Fields" md-card>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Index Field">
            {{ item.IndexFieldTitle }}
          </md-table-cell>
          <md-table-cell md-label="Page Number">
            <md-field>
              <md-input id="pageNumber" v-model="item.PageNumber" type="number" />
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="Barcode Number">
            <md-field>
              <md-input id="barcodeNumber" v-model="item.BarcodeNumber" type="number" />
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="From">
            <md-field>
              <md-input id="from" v-model="item.From" type="number" />
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="To">
            <md-field>
              <md-input id="to" v-model="item.To" type="number" />
            </md-field>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="rightAlign">
        <md-button type="submit" class="md-dense md-raised md-primary mt-4">
          Save
        </md-button>
        <md-button id="btnCancelBarcodeConfig" class="md-dense md-raised md-default mt-4" @click="closeWindow">
          Cancel
        </md-button>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: 'ExistingBarcodeIndexingEditor',
  props: {
    itemId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      attributeFormName: '',
      attributeFormValueId: this.itemId,
      barcodeAsFileName: false,
      barcodeNumber: '',
      configName: '',
      pageNumber: '',
      selected: [],
      selectedAttributeForm: 1,
      selectedAttributeFormArray: [],
    }
  },
  computed: {
    selectedTypeObject() { return this.$store.getters['mainViews/getBarcodeIndexingById'](this.itemId) },
  },
  created() {
    this.$store.dispatch('mainViews/getAllBarcodeIndex')
    this.$store.dispatch('mainViews/getIndexFields', 1)
  },
  methods: {
    closeWindow() {
      this.$emit('closeWindow')
    },
    newReportTypeSelection(type) {
      this.attributeFormValueIndex = type
      this.$store.dispatch('mainViews/getIndexFields', type)
    },
    onSelect(items) {
      this.selected = items
    },
    saveIndex() {
      this.$store.dispatch('mainViews/saveBarcodeIndex', this.selectedTypeObject)
      this.closeWindow()
    },
  },
};
</script>

<style lang="scss" scoped>
  .mainDiv {
    margin: 10px 10px 10px 10px;
    overflow: auto;
  }

  .rightAlign {
    text-align: right;
  }
</style>
